import React, { Component } from 'react';

export default class Paginator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pager: {}
    };
  }

  componentWillMount() {
    if (this.props.itemsLength) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.itemsLength !== prevProps.itemsLength) {
      this.setPage(this.props.initialPage);
    }
  }

  getPager(totalItems, currentPage, pageSize) {
    currentPage = this.props.page || 1;
    // default page size is 20
    pageSize = pageSize || 20;

    let totalPages = Math.ceil(totalItems / pageSize);
  
    let startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // if more than 10 total pages so calculate start and end pages
      if (currentPage <= 5) {
        startPage = 1;
        endPage = 6;
      } else if (currentPage + 4 >= totalPages) {
        endPage = totalPages;
        startPage = totalPages - 5;
      } else {
        startPage = currentPage - 3;
        endPage = currentPage + 2;
      }
    }

    let pages = [];
    for (let i = startPage; i < (endPage + 1); i++) {
      pages.push(i);
    }
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      pages: pages
    };
  }

  setPage = (page) => {
    const itemsLength = this.props.itemsLength;
    let pager = this.state.pager;
    if (page < 1 || page > pager.totalPages) {
      return;
    }
    pager = this.getPager(itemsLength, page);
    const perPage = pager.pageSize;
    this.setState({ pager: pager });
    this.props.onChangePage(page, perPage);
  }

  render() {
    let pager = this.state.pager;
    if (!pager.pages || pager.pages.length <= 1) {
      return null;
    }

    return (
      <nav>
        <ul className="pagination">
          <li className={pager.currentPage === 1 ? 'first disabled' : 'first'}>
            <a onClick={() => this.setPage(1)}>« First</a>
          </li>
          <li className={pager.currentPage === 1 ? 'prev disabled' : 'prev '}>
            <a onClick={() => this.setPage(pager.currentPage - 1)}>‹ Prev</a>
          </li>
          {pager.pages.map((page, index) =>
            <li key={index} className={pager.currentPage === page ? 'active' : ''}>
              <a onClick={() => this.setPage(page)}>{page}</a>
            </li>
          )}
          <li className={pager.currentPage === pager.totalPages ? 'next disabled' : 'next'}>
            <a onClick={() => this.setPage(pager.currentPage + 1)}>Next ›</a>
          </li>
          <li className={pager.currentPage === pager.totalPages ? 'last disabled' : 'last'}>
            <a onClick={() => this.setPage(pager.totalPages)}>Last »</a>
          </li>
        </ul>
      </nav>
    );
  }
}

import React, { Component } from 'react';

export default class UserAvatar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDefault: this.isDefaultAvatar()
    }
  }

  isDefaultAvatar() {
    return (!this.props.url);
  }

  getInitials(name) {
    if (name) {
      let initials = name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
      initials = initials.join('').substring(0,3).toUpperCase();
      return initials;
    } else {
      return "";
    }
  }

  render() {
    if (this.state.isDefault) {
      return (
        <img src="/assets/icons/default-avatar.png" alt="Avatar" />
      );
    } else if (this.props.displayInitials) {
      const initials = this.getInitials(this.props.name);
      if (initials.length > 0) {
        return (
          <div className={`initial-avatar-${this.props.size}px`} style={{backgroundColor: this.props.hex}}>
            <span className="initials">
              {initials}
            </span>
          </div>
        )
      }
    } else {
      return (
        <img src={this.props.url} alt="Avatar" />
      );
    }
  }

}

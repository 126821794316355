import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    Sentry.init({ dsn: 'https://c8d0886d21594780bba93bfcaa783cf0@sentry.io/1266222' });
    if(process.env.NODE_ENV === 'production') {
      Sentry.captureException(error);
    }
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return "";
    }
    return this.props.children;
  }
}

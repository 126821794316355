import React, { Component, Fragment } from 'react';

export default class Share extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      showShare: false
    }
  }

  handleClick() {
    if (!this.state.showShare) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showShare: !prevState.showShare
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  }

  toggleShare = () => {
    this.setState({
      showShare: !this.state.showShare
    })
  }

  handleFacebookShare = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.props.url}`,
      'facebook-share-dialog',
      'width=800,height=600');
  }

  handleTwitterShare = () => {
    window.open(`http://twitter.com/share?url=${this.props.url};text=${I18n.t('global.share_text', { name: this.props.name })};size=l&amp;count=none`,
      '_blank');
  }

  shareButton = () => {
    if (this.props.icon) {
      return (
        <Fragment>
          <div onClick={this.handleClick} className="icon cursor-pointer">
            <img src="/assets/icons/share.svg" alt={I18n.t('global.share')} />
          </div>
          {/*   <p onClick={this.handleClick} className="cursor-pointer">{I18n.t('global.share')}</p> */}
        </Fragment>
      );
    } else {
      return <a className="btn-green w-100 my-2" onClick={this.handleClick}>Share</a>;
    }
  }

  render() {
    return (
      <div ref={node => { this.node = node; }}>
        {this.shareButton()}
        {
          this.state.showShare &&
          <div className="share-social-icons">
            <ul>
              <li>
                <a onClick={this.handleFacebookShare} className="cursor-pointer" title={I18n.t('global.share_facebook')}>
                  <img src="/assets/fb-icon.png" alt="Facebook" />
                </a>
              </li>
              <li>
                <a onClick={this.handleTwitterShare} className="cursor-pointer" title={I18n.t('global.share_twitter')} target="_blank">
                  <img src="/assets/twiter-icon.png" alt="Twitter" />
                </a>
              </li>
            </ul>
          </div>
        }
      </div>
    );
  }
}
